<template>
  <div class="signin-page page">
    <HeadBar class="headbar" title="签到领VIP"></HeadBar>
    <div class="signin-page-content">
      <p v-if="isVip" class="signin-page-content-vip">会员有效期至：{{ vipInfo.end_time.split(' ')[0] }}</p>
      <p v-else class="signin-page-content-vip">您还不是VIP~</p>
      <div class="signin-page-content-progress">
        <StepComponent barClass="step-item-bar-width" />
      </div>
      <div class="signin-page-content-bot">
        <div v-if="isShowSigninBtn !== 1" class="signin-page-content-btn" @click="handleSignIn">{{ isSigninVipDay ? '领取' : '签到' }}</div>
        <div v-else class="signin-page-content-btn signin-page-content-btn-gray">{{ isSigninVipDay ? '已领取' : '已签到' }}</div>
      </div>
    </div>
    <div class="signin-page-intro">
      <p>活动规则介绍：</p>
      <p>1.此任务为不定期福利任务，任务期间将根据每位用户的累积签到天数进行发放奖励；</p>
      <p>2.奖励为VIP会员时效，会根据不同完成度（累积签到天数）领取对应奖励；</p>
      <p>3.任务于{{ start }}开始，于{{ end }}结束，任务结束后奖励不可领取；</p>
      <p>4.此任务必须在登录状态下进行，只有登录后才允许进行签到。</p>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import useVip from '@/composables/useVip'
import StepComponent from './components/step'
import { get, debounce } from 'lodash'

export default {
  name: 'SignInPage',
  components: {
    StepComponent,
  },
  setup() {
    const store = useStore()
    const { showVipQrcode } = useVip()
    const userInfo = computed(() => store.state.userInfo)
    const vipInfo = computed(() => store.state.vipInfo)
    const isLogin = computed(() => !!userInfo.value.unionid)
    const isVip = computed(() => !!vipInfo.value.end_time)
    const signInData = computed(() => store.state.signin.signInData)
    const nowActivityDay = computed(() => store.state.signin.nowActivityDay)
    const isShowSigninBtn = computed(() => get(signInData.value, `${nowActivityDay.value}.sign_status`, 1)) // signInData.value[nowActivityDay.value]['sign_status']
    const isSigninVipDay = computed(() => get(signInData.value, `${nowActivityDay.value}.isVipDay`, false))
    const mac_id = computed(() => store.state.macAddress)
    const start = computed(() => store.state.signin.signInActStart)
    const end = computed(() => store.state.signin.signInActEnd)

    const handleSignIn = debounce(() => {
      if (!isLogin.value) {
        showVipQrcode()
        return
      }

      // 签到
      store.dispatch('signin/signInToday', {
        unionid: userInfo.value.unionid, 
        mac_id: mac_id.value
      })
    }, 800)

    return {
      isVip,
      vipInfo,
      signInData,
      nowActivityDay,
      isShowSigninBtn,
      isSigninVipDay,
      start,
      end,
      handleSignIn,
    }
  }
}
</script>

<style lang="stylus" scoped>
.signin-page
  width 100%
  height 100%
  padding-top 35px
  position relative
  color #fff
  font-weight 400
  &-content
    width 100%
    height 100%
    display flex
    align-items center
    justify-content center
    flex-direction column
    &-progress
      width 1400px
      height 300px
      display flex
      justify-content center
      align-items center
      @media screen and (max-width 1200px)
        width 900px
    &-bot
      width 100%
      height 75px
      // margin-top 100px
      display flex
      justify-content center
    &-btn
      width 300px
      height 90px
      line-height 90px
      text-align center
      font-size 32px
      border-radius 14px
      color rgba(0, 0, 0, 0.8)
      background linear-gradient(90deg, #FBD7AF 0.13%, #F1BE73 100%)
    &-btn-gray
      color rgba(255, 255, 255, 0.3)
      background #383A3E
    &-vip
      color rgba(255, 255, 255, 0.8)
      font-size 30px
      margin-top 55px
      padding-left 170px
      width 100%
  &-intro
    position absolute
    left 80px
    bottom 88px
    font-size 28px
    color rgba(255, 255, 255, 0.5)
    @media screen and (max-width 1200px)
      bottom auto
      top 800px
    p
      height 32px
      margin-bottom 16px
      &:nth-child(1)
        margin-bottom 30px
      &:nth-child(5)
        margin-bottom 0px
</style>